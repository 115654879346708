import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import LayoutTmpl from "../components/layout";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from "@material-ui/lab/Alert";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip';

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));

const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));

const StyledTypography = styled(Typography)(({theme}) => ({
    fontSize: '1.1rem',
    paddingTop: "10px",
    [theme.breakpoints.down('sm')]: {
        fontSize: "0.8rem"
    },
}));

const StyledTableCellTh = styled(TableCell)(({theme}) => ({
    fontSize: '1.1rem',
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
        fontSize: "0.7rem"
    },
}));

const StyledTableCellTd = styled(TableCell)(({theme}) => ({
    fontSize: '1.1rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: "0.8rem"
    },
}));

const StyledChip = styled(Chip)(({theme}) => ({
    fontSize: '1rem',
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
        fontSize: "0.8rem"
    },
}));

let userId = 0;

if (typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search);
    userId = roughScale(queryParams.get('userId') === null ? 0 : queryParams.get('userId'));
}

function roughScale(id) {
    const parsed = parseInt(id);
    if (isNaN(parsed)) {
        return 0;
    }
    return parsed;
}

const Users = () => {

    const [usersView, setUsersView] = useState([])
    const [userView, setUserView] = useState({})
    const [title, setTitle] = useState("Рейтинг пользователей")
    const [dataLoading, setDataLoading] = useState(true)

    useEffect(() => {
        let values;
        let formData = new FormData();
        formData.append(
            "userId", userId,
        );
        values = Object.assign(formData);
        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/users-get.php",
            responseType: 'json',
        })
            .then(res => {
                if (res.data) {
                    if (userId > 0) {
                        setUserView(res.data);
                        setTitle(res.data.nickname ? res.data.nickname : res.data.name+" "+res.data.lastName+" "+res.data.secondName)
                    } else {
                        setUsersView(res.data);
                    }
                    setDataLoading(false)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{title}</title>
                <meta name="description" content={title}/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <LayoutTmpl>

                <main>

                    <StyledContainer maxWidth="md">

                        {dataLoading &&
                        <StyledPaper
                            style={{
                                margin: '20px 0px 20px 0px'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}
                                      style={{
                                          textAlign: 'center'
                                      }}>
                                    <>
                                        <Typography variant="body2" gutterBottom><b>Загрузка...</b></Typography>
                                        <CircularProgress/>
                                    </>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                        }

                        {!dataLoading &&
                        <StyledPaper>

                            {
                                usersView.length > 1 &&
                                <>
                                    <Typography gutterBottom component="h1" variant="h5"
                                                color="textPrimary">Рейтинг пользователей</Typography>
                                    <Alert icon={<InfoOutlinedIcon fontSize="inherit"/>} severity="info"
                                           style={{
                                               margin: "20px 10px 15px 10px",
                                               fontSize: "0.9rem"
                                           }}
                                    >
                                        Авторитет и рейтинг пользователей пересчитывается каждый день и зависит от
                                        размера активного сообщества и активности каждого пользователя. Рейтинг повышает
                                        публикация объектов и отзывов. Авторитет повышает или понижает голоса
                                        пользователей за отывы.
                                    </Alert>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{
                                                        width: "57px"
                                                    }}>&nbsp;</TableCell>
                                                    <StyledTableCellTh>Имя</StyledTableCellTh>
                                                    <StyledTableCellTh>Рейтинг</StyledTableCellTh>
                                                    <StyledTableCellTh>Авторитет</StyledTableCellTh>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {usersView.map((row, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell style={{
                                                            width: "57px"
                                                        }}>{
                                                            row.avatar ?
                                                                <Avatar
                                                                    alt={row.nickname ? row.nickname : row.name+" "+row.lastName+" "+row.secondName}
                                                                    src={row.avatar}/> :
                                                                <Avatar
                                                                    alt={row.nickname ? row.nickname : row.name+" "+row.lastName+" "+row.secondName}><PersonOutlineOutlinedIcon/></Avatar>
                                                        }</TableCell>
                                                        <TableCell>
                                                            <StyledTypography gutterBottom variant="body2"
                                                                              component="p"
                                                                              color="textPrimary"
                                                            ><Link href={"/users?userId=" + row.id}
                                                                   style={{
                                                                       color: '#3f51b5',
                                                                       textShadow: 'none',
                                                                       backgroundImage: 'none'
                                                                   }}>
                                                                {
                                                                    i === 0 && <StyledChip size="small" style={{
                                                                        margin: "0px 10px 5px 0px",
                                                                        backgroundColor: "#CFB53B",
                                                                        color: 'white',
                                                                        padding: "3px 1px 1px 1px",
                                                                        boxShadow: "0px 2px 2px #666"
                                                                    }} label="1"/>
                                                                }
                                                                {
                                                                    i === 1 && <StyledChip size="small" style={{
                                                                        margin: "0px 10px 5px 0px",
                                                                        backgroundColor: "#E6E8FA",
                                                                        color: 'black',
                                                                        padding: "3px 1px 1px 1px",
                                                                        boxShadow: "0px 2px 2px #666"
                                                                    }} label="2"/>
                                                                }
                                                                {
                                                                    i === 2 && <StyledChip size="small" style={{
                                                                        margin: "0px 10px 5px 0px",
                                                                        backgroundColor: "#8C7853",
                                                                        color: 'white',
                                                                        padding: "3px 1px 1px 1px",
                                                                        boxShadow: "0px 2px 2px #666"
                                                                    }} label="3"/>
                                                                }
                                                                {row.nickname ? row.nickname : row.name+" "+row.lastName+" "+row.secondName}</Link>
                                                            </StyledTypography>

                                                        </TableCell>
                                                        <StyledTableCellTd>{row.rating}</StyledTableCellTd>
                                                        <StyledTableCellTd>{row.mana}</StyledTableCellTd>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            }
                            {
                                userView.id !== undefined &&
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Grid
                                            style={{
                                                margin: '-5px 15px 0px 0px',
                                            }}
                                        >
                                            {
                                                userView.avatar ?
                                                    <Avatar alt={userView.nickname ? userView.nickname : userView.name}
                                                            src={userView.avatar}/> :
                                                    <Avatar
                                                        alt={userView.nickname ? userView.nickname : userView.name}><PersonOutlineOutlinedIcon/></Avatar>
                                            }
                                        </Grid>
                                        <Grid>
                                            <Typography gutterBottom component="h1" variant="h5"
                                                        color="textPrimary">{userView.nickname ? userView.nickname : userView.name}</Typography>
                                        </Grid>
                                    </Grid>

                                    <List style={{
                                        width: '100%',
                                        maxWidth: 260,
                                    }}>
                                        <ListItem>
                                            <ListItemText>
                                                Рейтинг:
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                {userView.rating}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>
                                                Авторитет:
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                {userView.mana}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>
                                                Отзывов:
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                {userView.reviewsPublic}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>
                                                Объектов:
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                {userView.elementsPublic}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>

                                    <Typography>
                                        <Link href="/users"
                                              style={{
                                                  color: '#3f51b5',
                                                  textShadow: 'none',
                                                  backgroundImage: 'none',
                                              }}>Рейтинг пользователей</Link>
                                    </Typography>
                                </>
                            }

                        </StyledPaper>
                        }

                    </StyledContainer>

                </main>

            </LayoutTmpl>
        </>
    );

}

Users.propTypes =
    {
        window: PropTypes.func,
    }
;

export default Users